<template>
  <div>
    <b-row
      class="mx-auto mt-4"
      style="width: 800px"
    >
      <b-col class="col mb-2 d-flex align-items-center justify-content-center">
        <b-img-lazy
          :src="require('@/assets/images/logo/track.png')"
          width="200px"
          alt="tracking"
        />
      </b-col>
      <b-col>
        <h2 class="text-primary font-weight-bolder">
          {{ $t('Track & Trace') }}
        </h2>
        <validation-observer ref="simpleRules">
          <b-form>
            <b-form-group
              :label="$t('delivery_id')"
              label-for="deliveryId"
            >
              <validation-provider
                #default="{ errors }"
                name="Delivery ID"
                rules="required"
              >
                <b-form-input
                  id="id"
                  v-model="form.id"
                  :placeholder="$t('enter_delivery_id')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <validation-provider
              #default="{ errors }"
              name="reCAPTCHA"
              rules="required"
            >
              <vue-recaptcha
                ref="recaptcha"
                v-model="form.recaptcha"
                sitekey="6LfoCf8fAAAAAAYiVz85sf1jN8Uk6fJKnRXeZhW9"
                @verify="isVerify"
                @expired="expired"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            <b-button
              type="submit"
              variant="danger"
              class="mr-1 mt-2"
              block
              @click.prevent="validationForm"
            >
              <b-spinner
                v-if="isLoading"
              />
              <span v-else>{{ $t('submit') }}</span>
            </b-button>
          </b-form>
        </validation-observer>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BFormInput, BRow, BCol, BFormGroup,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { VueRecaptcha } from 'vue-recaptcha'
import { required } from '@validations'
import axios from '@axios'

export default {
  name: 'BaseTrack',
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    VueRecaptcha,
  },
  data() {
    return {
      form: {
        id: null,
        recaptcha: null,
      },
      isLoading: false,
      required,
    }
  },
  methods: {
    async validationForm() {
      this.isLoading = true
      this.$refs.simpleRules.validate()
        .then(async res => {
          if (res) {
            const response = await axios.get(`deliveries/${this.form.id}`)
            if (response.status === 200) {
              this.isLoading = false
              this.$router.push({ path: `/track-order/${this.form.id}` })
              return
            }
            this.isLoading = false
            this.$swal({
              title: this.$t('id_not_found'),
              text: this.$t('delivery_not_exist'),
              icon: 'error',
              customClass: {
                confirmButton: 'btn-primary',
              },
            })
          }
        })
    },
    isVerify(e) {
      this.form.recaptcha = e
    },
    expired() {
      this.form.recaptcha = null
    },
  },
}
</script>
